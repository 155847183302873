<template>
  <div>
    <v-container fill-height fluid class="align-center justify-center">

      <div class="auth-wrapper auth-v1">
        <v-row class="align-center justify-center">
          <!-- Socialize block component -->
          <v-col cols="12" md="6" lg="5">
            <socialize-block-component></socialize-block-component>
          </v-col>

          <!-- Sing In form -->
          <v-col cols="10" md="6" lg="5" class="center mx-auto py-auto align-center justify-center ">
            <v-card class="align-center justify-center my-auto px-8 py-8 mx-auto h-full">
              <!-- title -->

              <span class="error--text" v-if="this.$store.getters.getColaboration.initialized == true">Nous avez initie
                une requete! elle sera evoye apres votre connexion!</span>

              <v-card-title class="d-flex py-auto align-center justify-center">
                <h1 class="font-weight-bolder">
                  Sign In
                </h1>
              </v-card-title>
              <v-card-text>
                <p class="subtitle-2 text-center mx-6">
                  Search partners and collaborate with professionnals
                </p>
              </v-card-text>
              
              <login-form></login-form>
               
              <v-card-text class="d-flex align-center mt-2">
                <v-divider></v-divider>
                <span class="mx-5">or</span>
                <v-divider></v-divider>
              </v-card-text>
              <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                <span class="me-2">
                  Don’t have an account yet?
                </span>
                <router-link :to="{ name: 'register' }">
                  Create an account
                </router-link>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import SocializeBlockComponent from '@/layouts/components/SocializeBlockComponent.vue'
import LoginForm from './login/LoginForm.vue'
export default {
  components: {
    SocializeBlockComponent,
    LoginForm
  },
  setup() {
    let icons = {
      mdiEyeOutline,
      mdiEyeOffOutline,
    }
    return {
      icons,
    }
  },
  computed: {
    // formatedData()
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

//@override
.v-card {
  border-radius: 1em !important;
}

h1 {
  margin: 0 !important;
}

.text-transform-none {
  text-transform: none;
}
</style>
